import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemStatus } from '@backend/api/system-status/models/system-status';

@Injectable({
  providedIn: 'root',
})
export class SystemStatusApiService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get<SystemStatus>('status');
  }

  healthCheck() {
    return this.http.get('health-check');
  }
}
